import Tabs from '@/components/Tabs'
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/settings/teams/$id')({
  component: ShowTeam,
})

function ShowTeam() {
  return (
    <div className="flex flex-1 flex-col gap-6">
      <Tabs
        tabs={[
          { name: "General", to: "/settings/teams/$id", activeOptions: { exact: true } },
          { name: "Stages", to: "/settings/teams/$id/stages" },
          { name: "Statuses", to: "/settings/teams/$id/statuses" },
        ]}
      />
      <Outlet />
    </div>
  )
}
