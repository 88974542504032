import { createFileRoute } from "@tanstack/react-router";
import { FormStateTransition } from "./-FormStateTransition";
import { usePatchStateTransition, useStateTransitionsData } from "@/api/StateTransitions";
import { Skeleton } from "@/ui/skeleton";
import { StateTransition } from "@/types/state_transition";

export const Route = createFileRoute("/_authenticated/settings/teams/$id/stages")({
  component: Stages,
});

interface TransitionEvent {
  activity_type_key: string;
  transition_name: string;
}

function Stages() {
  const { id } = Route.useParams();
  const { data, isLoading } = useStateTransitionsData(id);
  const { mutate } = usePatchStateTransition(id);
  let defaultValues: { events: TransitionEvent[] } = {
    events: [
      { activity_type_key: "", transition_name: "" },
      { activity_type_key: "", transition_name: "" },
    ],
  };

  if (data && data.length > 0) {
    defaultValues = {
      events: [
        ...data.map((transition) => ({
          activity_type_key: transition.from_activity_type_key,
          transition_name: transition.name,
        })),
        { activity_type_key: data[data.length - 1].to_activity_type_key, transition_name: "" },
      ],
    };
  }

  const getTransitionPairs = (events: TransitionEvent[]): StateTransition[] => {
    const pairs: StateTransition[] = [];

    for (let i = 0; i < events.length - 1; i++) {
      pairs.push({
        from_activity_type_key: events[i].activity_type_key,
        to_activity_type_key: events[i + 1].activity_type_key,
        name: events[i].transition_name,
      });
    }

    return pairs;
  };

  const onSubmit = (data: { events: TransitionEvent[] }) => {
    const transitionPairs = getTransitionPairs(data.events);
    mutate(transitionPairs);
  };

  if (isLoading) return <Loading />;

  return <FormStateTransition defaultValues={defaultValues} onSubmit={onSubmit} />;
}

function Loading() {
  return (
    <div className="flex h-screen p-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-4">
          <Skeleton className="h-10 w-[200px]" />
          <Skeleton className="h-10 w-0.5" />
          <Skeleton className="h-10 w-[200px]" />
        </div>
        <div className="flex flex-col items-center gap-4">
          <Skeleton className="h-10 w-0.5" />
          <Skeleton className="h-10 w-[200px]" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="h-10 w-[100px]" />
          <Skeleton className="h-10 w-[100px]" />
        </div>
      </div>
    </div>
  );
}
