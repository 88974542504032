import { useFilterData, useUpdateFilter } from "../../../../api/Filters";
import { createFileRoute } from "@tanstack/react-router";
import FormFilter from "./-FormFilter";
import { Skeleton } from "../../../../components/ui/skeleton";
import { FilterGroup } from "../../../../../src/types/filter";

export const Route = createFileRoute("/_authenticated/settings/filters/$filterId")({
  component: () => <ShowFilter />,
});

function ShowFilter() {
  const { filterId } = Route.useParams();
  const { data, isLoading } = useFilterData(filterId);
  const { mutate } = useUpdateFilter();
  if (isLoading) return <Loading />;
  if (!data) return <div>No data</div>;
  const defaultValues = {
    name: data.name,
    filter_group: data.filter_group,
  };
  const onSubmit = (submitted_data: {name: string, filter_group: FilterGroup}) => {
    mutate({ filterId, data: submitted_data });
  };
  return <FormFilter onSubmit={onSubmit} defaultValues={defaultValues} SubmitText="Update Filter" ResetText="Cancel" />;
}

function Loading() {
  return (
    <div className="flex h-screen p-6">
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Skeleton className="h-4 w-10" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-5 w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton className="h-4 w-10" />
          <Skeleton className="h-10 w-full" />
          <Skeleton className="h-5 w-full" />
        </div>
      </div>
    </div>
  );
}
