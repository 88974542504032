import { createFileRoute } from "@tanstack/react-router";
import { FormStatus } from "./-FormStatus";
import { useBulkUpdateStatuses, useStatusesData } from "@/api/Statuses";

export const Route = createFileRoute("/_authenticated/settings/teams/$id/statuses")({
  component: () => <Statuses />,
});

function Statuses() {
  const { id } = Route.useParams();
  const { data, isLoading } = useStatusesData(id);
  const { mutate } = useBulkUpdateStatuses(id);
  let defaultValues: any = { statuses: [] };

  if (isLoading) return <div>Loading...</div>;
  if (data) {
    defaultValues = {
      statuses: data,
    };
  }

  const onSubmit = (data: any) => {
    mutate(data);
  };

  return <FormStatus defaultValues={defaultValues} onSubmit={onSubmit} />;
}
