import React from "react";
import { DateRange } from "react-date-range";
import dayjs from "dayjs";
import classNames from "classnames";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import CalendarIcon from "../Icon/CalendarIcon";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { DATE_FORMAT } from "../../constants";

const DateRangePicker = ({dateRange, setDateRange}: {dateRange: string[], setDateRange: (dateRange: string[]) => void}) => {
  const [state, setState] = React.useState([
    {
      startDate: dayjs(dateRange[0]).toDate(),
      endDate: dayjs(dateRange[1]).toDate(),
      key: "selection",
      color: "#3636eb",
    },
  ]);
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      const startDate = dayjs(state[0].startDate).utc(true).format(DATE_FORMAT);
      const endDate = dayjs(state[0].endDate).utc(true).format(DATE_FORMAT);
      setDateRange([startDate, endDate]);
    }
  };

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger
        className={classNames(
          "flex h-8 min-w-48 cursor-default items-center justify-between gap-6 rounded-2xl border px-3",
          { "border hover:border-gray-400": !isOpen, "border-blue-600 ring ring-blue-200": isOpen },
        )}>
        <span className="text-sm text-gray-500">{`${dayjs(state[0].startDate).format(DATE_FORMAT)} \u2013 ${dayjs(state[0].endDate).format(DATE_FORMAT)}`}</span>
        <CalendarIcon classNames="text-blue-600" />
      </PopoverTrigger>
      <PopoverContent className="w-auto overflow-hidden rounded-2xl p-0">
        <DateRange
          onChange={(item) => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          preventSnapRefocus={true}
          calendarFocus="backwards"
          displayMode="dateRange"
          minDate={dayjs().subtract(2, "year").startOf("year").toDate()}
          maxDate={new Date()}
          direction="vertical"
          scroll={{ enabled: false }}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DateRangePicker;
