import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icons } from "@/components/Utils/Icons";
import { Form, FormControl, FormField, FormItem } from "@/ui/form";
import { Button } from "@/ui/button";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectTrigger, SelectItem, SelectValue } from "@/ui/select";
import React, { useState, useEffect } from "react";
import _ from "lodash";

const stageSchema = z.object({
  events: z
    .array(
      z.object({
        activity_type_key: z.string().min(1, "Event is required"),
        transition_name: z.string(),
      }),
    )
    .min(2, "At least two events are required")
    .superRefine((events, ctx) => {
      const totalLength = events.length;
      events.map((event, index) => {
        if (index < totalLength - 1 && !event.transition_name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Transition name is required",
            path: [index, "transition_name"],
          });
        }
      });
      return events;
    }),
});

interface FormStateTransitionProps {
  defaultValues: z.infer<typeof stageSchema>;
  onSubmit: (data: z.infer<typeof stageSchema>) => void;
}

const allEvents = [
  "status_in_progress",
  "status_ready_for_review",
  "review_requested",
  "status_in_review",
  "first_review_received",
  "review_approved",
  "status_ready_for_qa",
  "status_in_qa",
  "first_bug_found",
  "status_ready_for_release",
  "status_closed",
];

export const FormStateTransition: React.FC<FormStateTransitionProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm({
    resolver: zodResolver(stageSchema),
    defaultValues: defaultValues,
  });
  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "events",
  });
  const watch = form.watch;

  const [selectedEvents, setSelectedEvents] = useState<string[]>(_.map(defaultValues.events, "activity_type_key"));

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      const currentSelectedEvents = _.map(value.events, "activity_type_key").filter((e: string) => e !== "");
      setSelectedEvents(currentSelectedEvents);
    });
    return () => unsubscribe();
  }, [watch]);

  const getUsedEvents = (currentValue: string) => {
    return selectedEvents.filter((event) => event !== currentValue);
  };

  const getAvailableEvents = (currentValue: string) => {
    return allEvents.filter((event) => !selectedEvents.includes(event) || event === currentValue);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, (errors) => console.log("Errors:", errors))} className="space-y-6">
        <div className="flex flex-col gap-4">
          {fields.map((field, index) => (
            <div key={field.id} className="flex items-start gap-4">
              <div className="flex flex-col items-center gap-4">
                <FormField
                  control={form.control}
                  name={`events.${index}.activity_type_key`}
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger className="w-[200px]" aria-invalid={!!fieldState.error}>
                          <SelectValue placeholder="Select event" />
                        </SelectTrigger>
                        <SelectContent>
                          {getUsedEvents(field.value).map((event) => (
                            <SelectItem key={event} value={event} disabled>
                              {event}
                            </SelectItem>
                          ))}
                          {getAvailableEvents(field.value).map((event) => (
                            <SelectItem key={event} value={event}>
                              {event}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      {fieldState.error && <p className="mt-1 text-sm text-destructive">{fieldState.error.message}</p>}
                    </FormItem>
                  )}
                />

                {index < fields.length - 1 && <div className="h-10 w-0.5 bg-gray-400" />}
              </div>

              {index < fields.length - 1 && (
                <div className="flex gap-2 self-end">
                  <FormField
                    control={form.control}
                    name={`events.${index}.transition_name`}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder="Transition name"
                            className="w-[200px]"
                            aria-invalid={!!fieldState.error}
                          />
                        </FormControl>
                        {fieldState.error && (
                          <p className="mt-1 text-sm text-destructive">{fieldState.error.message}</p>
                        )}
                      </FormItem>
                    )}
                  />

                  {fields.length > 2 && (
                    <Button type="button" variant="ghost" onClick={() => remove(index)} className="text-gray-500">
                      <Icons.Trash />
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {form.formState.errors.events?.root && (
          <p className="text-sm text-destructive">{form.formState.errors.events.root.message}</p>
        )}

        <Button
          type="button"
          variant="outline"
          onClick={() => append({ activity_type_key: "", transition_name: "" })}
          className="flex gap-2 text-gray-500">
          <Icons.Plus size={16} />
          Add Event
        </Button>

        <div className="space-x-2">
          <Button type="button" variant="secondary" onClick={() => form.reset(defaultValues)}>
            Reset
          </Button>
          <Button type="submit">Save State Transitions</Button>
        </div>
      </form>
    </Form>
  );
};
