import { useQuery, UseQueryResult } from "react-query";
import client from "./client";
import { TIMESTAMP_FORMAT } from "@/constants";
import dayjs from "dayjs";
import { CycleTimeBreakdown } from "@/types/cycle_time_breakdown";

interface CycleTimeBreakdownData {
  data: CycleTimeBreakdown[];
}

// Fetch cycle time breakdown data for a team
export const useCycleTimeBreakdown = (teamId: string): UseQueryResult<CycleTimeBreakdownData> => {
  return useQuery<CycleTimeBreakdownData>({
    queryKey: ["cycleTimeBreakdown", teamId],
    queryFn: () => fetchCycleTimeBreakdown(teamId),
  });
};

const fetchCycleTimeBreakdown = async (teamId: string): Promise<CycleTimeBreakdownData> => {
  const response = await client.get(`/teams/${teamId}/cycle_time_breakdown`, {
    params: {
      start_time: dayjs.utc().subtract(90, "day").startOf("day").format(TIMESTAMP_FORMAT),
      end_time: dayjs.utc().endOf("day").format(TIMESTAMP_FORMAT),
    },
  });
  return response.data;
};
