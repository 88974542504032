import { cn } from "@/lib/utils";
import Table from "@/components/Table";
import KeyCell from "@/components/Table/cells/KeyCell";
import { Popover, PopoverContent, PopoverTrigger, PopoverArrow } from "@/components/ui/popover";
import { Icons } from "@/components/Utils/Icons";
import TitleCell from "@/components/Table/cells/TitleCell";
import { WorkItem } from "@/types/work_item";
import { useTeamWorkItems } from "@/api/Teams";
import _ from "lodash";

export const NeedAttentionChart = ({ teamId }: { teamId: string }) => {
  const { data: workItems, isLoading: isWorkItemsLoading } = useTeamWorkItems(teamId);
  const grouped: Record<string, WorkItem[]> = _.groupBy(workItems, "status_name");
  const groupedByCategory: Record<string, WorkItem[]> = _.groupBy(workItems, "status_category");
  const needsAttentionCount = groupedByCategory["Needs Attention"]?.length || 0;
  const atRiskCount = groupedByCategory["At Risk"]?.length || 0;
  const onTargetCount = groupedByCategory["On Target"]?.length || 0;
  const totalCount = workItems?.length || 0;

  const columns = [
    {
      accessorKey: "issue_key",
      header: "Key",
      maxSize: 120,
      cell: KeyCell,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "title",
      header: "Title",
      size: 400,
      cell: TitleCell,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "reason",
      header: "Reason",
      size: 400,
      cell: (props) => <p>{props.getValue()}</p>,
      enableColumnFilter: true,
      enableGlobalFilter: true,
      filterFn: "includesString",
    },
  ];
  return (
    <div className="overflow-hidden rounded-xl bg-white">
      <div className="flex flex-col self-stretch border-b px-4 py-2">
        <div className="text-base font-semibold">Work items that need attention</div>
        <div className="text-xs text-gray-600">Identify tasks at risk of missing deadlines</div>
      </div>
      <div className="flex flex-col gap-4 border-b p-4">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col">
            <div className="text-red-600">Needs Attention</div>
            <div>{needsAttentionCount}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-yellow-400">At Risk</div>
            <div>{atRiskCount}</div>
          </div>
          <div className="flex flex-col">
            <div className="text-green-600">On Target</div>
            <div>{onTargetCount}</div>
          </div>
          <div className="flex flex-col">
            <div>Total</div>
            <div>{totalCount}</div>
          </div>
        </div>
        <div className="flex gap-6">
          {Object.entries(grouped).map(([status, issues]) => (
            <div key={status} className="flex flex-1 flex-col gap-2 overflow-hidden">
              <div className="truncate font-semibold">{status}</div>
              <div className="flex flex-wrap gap-2">
                {issues.map((issue) => (
                  <Popover key={issue.issue_key}>
                    <PopoverTrigger>
                      <div
                        className={cn("size-4 rounded border", {
                          // "border-red-600 bg-red-500": issue.state === "Needs Attention",
                          // "border-yellow-400 bg-yellow-300": issue.state === "At Risk",
                          "border-green-500 bg-green-400": true,
                        })}></div>
                    </PopoverTrigger>
                    <PopoverContent className="shadow-md">
                      <PopoverArrow className="-translate-y-[1px] fill-white stroke-gray-200" />
                      <div className="flex flex-col gap-2">
                        <a href="#" className="link flex items-baseline gap-1">
                          <span className="text-sm">{issue.issue_key}</span>
                          <Icons.Link size={12} />
                        </a>
                        <span className="font-bold">{issue.title}</span>
                        <div className="flex gap-1">
                          <Icons.CircleAlert size={20} className="icon" />
                          <span className="text-sm">{issue.reason}</span>
                        </div>
                      </div>
                    </PopoverContent>
                  </Popover>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative overflow-x-auto">
        <Table data={workItems || []} columns={columns} defaultPageSize={5} isLoading={isWorkItemsLoading}>
          <Table.Main />
          {workItems && workItems.length > 5 && <Table.Pagination />}
        </Table>
      </div>
    </div>
  );
};