import Table from "../Table";
import KeyCell from "../Table/cells/KeyCell";
import StatusCell from "../Table/cells/StatusCell";
import AssigneeCell from "../Table/cells/AssigneeCell";
import { WorkItem } from "@/types/work_item";
import TitleCell from "../Table/cells/TitleCell";

const columns = [
  {
    accessorKey: "issue_key",
    header: "Key",
    maxSize: 120,
    cell: KeyCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "title",
    header: "Title",
    size: 400,
    cell: TitleCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "assignee",
    header: "Assignee",
    size: 160,
    cell: AssigneeCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "status_name",
    header: "Status",
    size: 160,
    maxSize: 160,
    cell: StatusCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];

const WorkItemsTable = ({ data, isLoading }: { data: WorkItem[]; isLoading: boolean }) => {
  return (
    <Table data={data} columns={columns} isLoading={isLoading}>
      <Table.Header name="Work items">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default WorkItemsTable;
