import { Link } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";
import { cn } from "../../lib/utils";
import { buttonVariants } from "../ui/button";
import {
  Sidebar,
  SidebarContent,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "../ui/sidebar";

const SidebarNav = ({ className, items, ...props }: { className?: string; items: any[] }) => {
  const pathname = useLocation({
    select: (location) => location.pathname,
  });

  return (
    <Sidebar variant="sidebar" collapsible="icon">
      <SidebarContent className="m-3">
        {items.map((item) => (
          <SidebarMenu key={item.title}>
            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                {item.href ? (
                  <Link to={item.href}>
                    {item.icon && <item.icon size={20} className="size-5" />}
                    {item.title}
                  </Link>
                ) : (
                  <span className="flex items-center justify-start gap-2 px-2 py-2 text-base">
                    {item.icon && <item.icon size={20} className="size-5" />}
                    {item.title}
                  </span>
                )}
              </SidebarMenuButton>
              <SidebarMenuAction>{item.action}</SidebarMenuAction>
            </SidebarMenuItem>
            <SidebarMenuSub>
              {item.subItems &&
                item.subItems.map((subItem: { title: string; href: string; icon: any }) => (
                  <SidebarMenuSubItem key={subItem.href}>
                    <SidebarMenuSubButton asChild>
                      <Link
                        to={subItem.href}
                        className={cn(
                          buttonVariants({ variant: "ghost" }),
                          "flex items-center justify-start gap-2 px-2 py-2 text-base",
                        )}>
                        {subItem.icon && <subItem.icon size={20} className="size-5" />}
                        <span>{subItem.title}</span>
                      </Link>
                    </SidebarMenuSubButton>
                  </SidebarMenuSubItem>
                ))}
            </SidebarMenuSub>
          </SidebarMenu>
        ))}
      </SidebarContent>
    </Sidebar>
  );
};

export default SidebarNav;
