import { createFileRoute } from "@tanstack/react-router";
import MetricTile from "@/components/MetricTile";
import SprintsChart from "@/components/SprintsChart";
import { useTeamDashboardMetrics } from "@/api/Teams";
import { Skeleton } from "@/ui/skeleton";
import { CycleTimeBreakdownChart } from "./-CycleTimeBreakdownChart";
import { NeedAttentionChart } from "./-NeedAttentionChart";

export const Route = createFileRoute("/_authenticated/teams/$id/")({
  component: Team,
});

function Team() {
  const { id } = Route.useParams();
  const { data: metrics, isLoading } = useTeamDashboardMetrics(id);

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-2 flex flex-col gap-6">
        <NeedAttentionChart teamId={id} />
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {isLoading && <MetricsLoading />}
          {metrics &&
            metrics.map((metric) => (
              <div
                key={metric.key}
                className="flex min-h-32 flex-col rounded-xl bg-white dark:border-neutral-700 dark:bg-neutral-800">
                <MetricTile metricKey={metric.key} teamId={id} />
              </div>
            ))}
        </div>
        <SprintsChart />
      </div>
      <div className="col-span-1">
        <CycleTimeBreakdownChart teamId={id} />
      </div>
    </div>
  );
}

function MetricsLoading() {
  return (
    <>
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
      <Skeleton className="min-h-32 w-full rounded-xl bg-white dark:bg-neutral-800" />
    </>
  );
}
