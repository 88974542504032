import { createFileRoute } from "@tanstack/react-router";
import FormFilter from "./-FormFilter";
import { useCreateFilter } from "../../../../api/Filters";
import { toast } from "../../../../components/ui/use-toast";

export const Route = createFileRoute("/_authenticated/settings/filters/new")({
  component: NewFilter,
});

function NewFilter() {
  const { mutate } = useCreateFilter();
  const onSubmit = (data: any) => {
    mutate(data);
  };
  const defaultValues = {
    name: "",
    filter_group: { conditions: [{ field: "", operator: "", value: "" }], combinator: "and" },
  };
  return <FormFilter onSubmit={onSubmit} defaultValues={defaultValues} SubmitText="Create Filter" ResetText="Cancel" />;
}
