import { Activity } from "@/types/activity";
import { Link } from "@tanstack/react-router";
import _ from "lodash";

export const getActivityDescription = (activity: Activity): React.ReactNode => {
  const prDescription = () => {
    if (!activity.pull_request) return null;
    return (
      <Link to={activity.pull_request.url} target="_blank" className="link">
        {activity.pull_request.repository}/{activity.pull_request.key}
      </Link>
    );
  };
  const type = activity.activity_type;
  const issue_key = activity.issue_key;
  const field = activity.field;
  const from = activity.from;
  const to = activity.to;
  switch (type) {
    case "issue_created":
      return (
        <>
          Created {issue_key}
        </>
      );
    case "issue_field_changed":
      return (
        <>
          {_.capitalize(field)} changed from <b>{from || "-"}</b> to <b>{to || "-"}</b>
        </>
      );
    case "issue_commented":
      return (
        <>
          Commented on {issue_key}
        </>
      );
    case "issue_status_changed":
      return (
        <>
          Changed {issue_key} status from <b>{from}</b> to <b>{to}</b>
        </>
      );
    case "issue_linked":
      return (
        <>
          Linked {issue_key} to {to}
        </>
      );
    case "pull_request_created":
      return (
        <>
          Opened {prDescription()} for {issue_key}
        </>
      );
    case "pull_request_merged":
      return (
        <>
          Merged {prDescription()}
        </>
      );
    case "pull_request_closed":
      return (
        <>
          Closed {prDescription()}
        </>
      );
    case "pull_request_review_requested":
      return (
        <>
          Requested review on {prDescription()}
        </>
      );
    case "pull_request_review_given":
      return (
        <>
          Reviewed {prDescription()}
        </>
      );
    case "pull_request_commented":
      return (
        <>
          Commented on {prDescription()}
        </>
      );
    default:
      return <>{type}</>;
      // throw new Error(`Unknown activity type: ${type}`);
  }
};