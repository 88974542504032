import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useQuery } from "react-query";
import client from "./client";
import { MetricData } from "../types/metric_data";
import { TIMESTAMP_FORMAT } from "@/constants";
dayjs.extend(utc);

export const fetchMetricTileData = async (key: string, teamId: string | number): Promise<MetricData> => {
  const response = await client.post(`/metrics/${key}/tile_data`, {
    filters: {
      start_time: dayjs.utc().subtract(90, "day").startOf("day").format(TIMESTAMP_FORMAT),
      end_time: dayjs.utc().endOf("day").format(TIMESTAMP_FORMAT),
      team_id: teamId,
    },
  });
  return response.data;
};

export const useMetricTileData = (key: string, teamId: string | number) => {
  return useQuery<MetricData>({
    queryKey: ["metric_data", key, teamId],
    queryFn: () => fetchMetricTileData(key, teamId),
  });
};
