import { Skeleton } from "@/components/ui/skeleton";
import { useCycleTimeBreakdown } from "@/api/CycleTimeBreakdown";
import _ from "lodash";
import { CycleTimeBreakdown } from "@/types/cycle_time_breakdown";

const adjustPercentages = (dataset: number[]): {duration: number, percentage: number}[] => {
  const total = _.sum(dataset);
  const percentages = dataset.map((item) => ({duration: item, percentage: item * 100 / total}));
  const floorSum = _.sum(percentages.map((item) => Math.floor(item.percentage)));
  const diff = 100 - floorSum;

  const sortedPercentages = percentages.sort((a, b) => (a.percentage - Math.floor(a.percentage)) - (b.percentage - Math.floor(b.percentage)));

  const roundedPercentages = sortedPercentages.map((item, index) => {
    const floorValue = Math.floor(item.percentage);
    return {
      ...item,
      percentage: index < diff ? floorValue + 1 : floorValue,
    };
  });

  return roundedPercentages;
}

const Title = () => {
  return (
    <div className="flex flex-col self-stretch border-b px-4 py-2">
      <div className="text-base font-semibold">Cycle time breakdown</div>
      <div className="text-xs text-gray-600">
        Track where the team is spending the most time to identify bottlenecks
      </div>
    </div>
  );
};

export const CycleTimeBreakdownChart = ({ teamId }: { teamId: string }) => {
  const { isLoading, data, isError } = useCycleTimeBreakdown(teamId);

  if (isLoading) {
    const barWidth = ["58%", "20%", "90%", "45%", "38%"];
    return (
      <div className="overflow-hidden rounded-xl bg-white">
        <Title />
        <div className="p-4">
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="relative flex gap-2">
              <div className="grow pb-4">
                <div className="flex justify-between">
                  <Skeleton className="h-4 w-32" />
                  <Skeleton className="h-4 w-24" />
                </div>
                <div className="flex w-full gap-1.5">
                  <Skeleton className="h-4 rounded-full" style={{ width: barWidth[index] }} />
                </div>
              </div>
              <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
                <div className="relative z-10 flex justify-end">
                  <Skeleton className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800" />
                </div>
              </div>
            </div>
          ))}
          <div className="relative flex gap-2">
            <div className="grow">
              <div className="float-right mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                <Skeleton className="h-4 w-24" />
              </div>
            </div>
            <Skeleton className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800" />
          </div>
        </div>
      </div>
    );
  } else if (isError) {
    return (
      <div className="overflow-hidden rounded-xl bg-white">
        <Title />
        <div className="p-4 text-center">Error during loading</div>
      </div>
    );
  } else if (data?.data.length === 0) {
    return (
      <div className="overflow-hidden rounded-xl bg-white">
        <Title />
        <div className="p-4 text-center">No data</div>
      </div>
    );
  }

  const originalResult: CycleTimeBreakdown[] = data?.data.sort((a, b) => a.transition_order - b.transition_order) || [];
  const result: {
    transition_name: string;
    activity_name: string;
    next_activity_name: string;
    percentage: string;
    width: string;
  }[] = [];
  const percentages = adjustPercentages(_.map(originalResult, "duration"));
  const biggestPercentage = _.max(percentages, "percentage")?.percentage || 0;
  originalResult.forEach((item) => {
    const percentage = percentages.find((percentage) => percentage.duration === item.duration)!.percentage;
    const width = `${((0.9 * percentage) / biggestPercentage) * 100}%`;
    result.push({
      transition_name: item.transition_name,
      activity_name: item.activity_name,
      next_activity_name: item.next_activity_name,
      percentage: `${percentage}%`,
      width,
    });
  });
  const lastItem = result[result.length - 1];

  return (
    <div className="overflow-hidden rounded-xl bg-white">
      <div className="flex flex-col self-stretch border-b px-4 py-2">
        <div className="text-base font-semibold">Cycle time breakdown</div>
        <div className="text-xs text-gray-600">
          Track where the team is spending the most time to identify bottlenecks
        </div>
      </div>
      <div className="p-4">
        {result.map((item) => (
          <div key={item.transition_name} className="relative flex gap-2">
            <div className="grow pb-4">
              <div className="flex justify-between">
                <div className="mb-1 text-sm font-normal leading-none">{item.transition_name}</div>
                <div className="mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
                  {item.activity_name}
                </div>
              </div>
              <div className="flex w-full gap-1.5">
                <div className="h-3 rounded-full bg-blue-600 dark:bg-blue-500" style={{ width: item.width }}></div>
                <div className="self-start text-sm font-bold leading-none">{item.percentage}</div>
              </div>
            </div>
            <div className="dark:team-hover:after:bg-neutral-600 relative after:absolute after:bottom-0 after:end-1.5 after:top-3 after:w-px after:translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
              <div className="relative z-10 flex justify-end">
                <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
              </div>
            </div>
          </div>
        ))}
        <div className="relative flex gap-2">
          <div className="grow">
            <div className="float-right mb-1 text-sm font-normal leading-none text-gray-500 dark:text-gray-500">
              {lastItem.next_activity_name}
            </div>
          </div>
          <div className="size-3 rounded-full border-2 border-blue-400 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
        </div>
      </div>
    </div>
  );
};