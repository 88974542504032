import { useTeamsData } from '@/api/Teams';
import { createFileRoute, useNavigate } from '@tanstack/react-router'

export const Route = createFileRoute('/_authenticated/teams/')({
  component: Teams,
});

function Teams() {
  const { data, isLoading, isError } = useTeamsData();
  const navigate = useNavigate();

  if (isLoading) {
    return null;
  }
  if (isError) {
    return <div>Error loading teams</div>;
  }
  if (data?.[0]?.id) {
    navigate({
      to: "/teams/$id",
      params: { id: data?.[0]?.id.toString() },
    });
  }
  return <div>No teams found</div>;
}
