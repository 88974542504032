import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";
import { Status } from "@/types/status";

export const useStatusesData = (teamId: number | string): UseQueryResult<Status[]> => {
  return useQuery<Status[]>({
    queryKey: ["statuses", teamId],
    queryFn: () => fetchStatuses(teamId),
  });
};

export const useBulkUpdateStatuses = (teamId: number | string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (statuses: Status[]) => bulkUpdateStatuses(teamId, statuses),
    onSuccess: (data) => {
      queryClient.setQueryData<Status[]>(["statuses", teamId], data);
    },
  });
};

export const fetchStatuses = async (teamId: number | string): Promise<Status[]> => {
  const response = await client.get(`/teams/${teamId}/statuses`);
  return response.data;
};

export const bulkUpdateStatuses = async (teamId: number | string, statuses: Status[]): Promise<Status[]> => {
  const response = await client.post(`/teams/${teamId}/statuses_bulk_update`, statuses);
  return response.data;
};
