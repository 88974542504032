import { useQuery, useMutation, useQueryClient, UseQueryResult } from "react-query";
import client from "./client";
import { StateTransition } from "@/types/state_transition";

export const useStateTransitionsData = (teamId: number | string): UseQueryResult<StateTransition[]> => {
  return useQuery<StateTransition[]>({
    queryKey: ["stateTransitions", teamId],
    queryFn: () => fetchStateTransitions(teamId),
  });
};

export const usePatchStateTransition = (teamId: number | string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newStateTransitions: Omit<StateTransition, "id">[]) => {
      const response = await client.post(`/teams/${teamId}/state_transitions`, {
        state_transitions: newStateTransitions,
      });
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<StateTransition[]>(["stateTransitions", teamId], data);
    },
  });
};

export const fetchStateTransitions = async (teamId: number | string): Promise<StateTransition[]> => {
  const response = await client.get(`/teams/${teamId}/state_transitions`);
  return response.data;
};