import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "@/ui/form";
import { Button } from "@/ui/button";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectTrigger, SelectItem, SelectValue } from "@/ui/select";
import { Switch } from "@/ui/switch";
import React from "react";

const statusSchema = z.object({
  statuses: z.array(
    z.object({
      id: z.number().optional(),
      name: z.string().min(1, "Status name is required"),
      category: z.string().default("backlog"),
      active: z.boolean().default(false),
    }),
  ),
});

interface FormStatusProps {
  defaultValues: z.infer<typeof statusSchema>;
  onSubmit: (data: z.infer<typeof statusSchema>) => void;
}

export const FormStatus: React.FC<FormStatusProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm({
    resolver: zodResolver(statusSchema),
    defaultValues: defaultValues,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "statuses",
  });

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {fields.map((field, index) => (
          <div key={field.id} className="flex flex-row items-center gap-4">
            <FormField
              control={form.control}
              name={`statuses.${index}.id`}
              render={({ field }) => <Input type="hidden" {...field} />}
            />
            <FormField
              control={form.control}
              name={`statuses.${index}.name`}
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormControl>
                    <Input {...field} placeholder="Status name" aria-invalid={!!fieldState.error} />
                  </FormControl>
                  {fieldState.error && <p className="mt-1 text-sm text-destructive">{fieldState.error.message}</p>}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`statuses.${index}.category`}
              render={({ field }) => (
                <FormItem>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="Select category" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="backlog">Backlog</SelectItem>
                      <SelectItem value="todo">To Do</SelectItem>
                      <SelectItem value="in_progress">In Progress</SelectItem>
                      <SelectItem value="completed">Completed</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`statuses.${index}.active`}
              render={({ field }) => (
                <FormItem>
                  <Switch checked={field.value} onCheckedChange={field.onChange} />
                </FormItem>
              )}
            />
          </div>
        ))}
        <Button type="submit">Save</Button>
      </form>
    </Form>
  );
};
