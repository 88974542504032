import _ from "lodash";

export const percentageTransform = {
  type: "andonPulse:percent",
  transform: (params) => {
    const upstream = params.upstream;
    const config = params.config || {};
    const dimension = config.dimension;
    const metric = config.metric;
    const rawDataset = upstream.cloneRawData();
    const uniqGroups = _.map(_.uniqBy(rawDataset, dimension), dimension);
    let newDataset = [];
    _.map(uniqGroups, (group) => {
      const rowsInGroup = _.filter(rawDataset, [dimension, group]);
      const total = _.sumBy(rowsInGroup, metric);
      _.map(rowsInGroup, (row) => {
        const { [metric]: value, ...rest } = row;
        const formatted_value = _.round(value / total, 10);
        newDataset.push({ ...rest, [metric]: formatted_value });
      });
    });
    return [
      {
        dimensions: upstream.cloneAllDimensionInfo(),
        data: newDataset,
      },
    ];
  },
};

// Function to nullify the data if combination of dimensions is not present.
export const nullifyTransform = {
  type: "andonPulse:nullify",
  transform: (params) => {
    const upstream = params.upstream;
    const config = params.config || {};
    const dimensions = config.dimensions;
    const metric = config.metric;
    const rawDataset = upstream.cloneRawData();
    let newDataset = [];
    const combinations = allCombinations(dimensions, rawDataset);
    _.map(combinations, (group) => {
      const row = _.find(rawDataset, group);
      if (row) {
        newDataset.push(row);
      } else {
        newDataset.push({ ...group, [metric]: 0.0 });
      }
    });
    console.log("rawDataset", rawDataset);
    console.log("dimensions", dimensions);
    console.log("combinations", combinations);
    console.log("andonPulse:nullifyTransform", newDataset);
    return [
      {
        dimensions: upstream.cloneAllDimensionInfo(),
        data: newDataset,
      },
    ];
  },
};

const allCombinations = (dimensions, rawDataset) => {
  const uniqValues = rawDataset.reduce((acc, row) => {
    _.forEach(dimensions, (dimension) => {
      if (!acc.some((item) => item[dimension] === row[dimension])) {
        acc.push({ [dimension]: row[dimension] });
      }
    });
    return acc;
  }, []);
  const uniqValueOfDimenstions = dimensions.map((dimension) => {
    const filter = _.filter(uniqValues, dimension);
    return _.map(filter, (item) => _.pick(item, dimension));
  });
  const product = cartesianProduct(uniqValueOfDimenstions);
  return _.map(product, (arr) => Object.assign({}, ...arr));
};

const cartesianProduct = (arr: any[][]): any[][] => {
  return arr.reduce((res: any[][], a: any[]) => res.flatMap((x) => a.map((y) => [...x, y])), [[]]);
};