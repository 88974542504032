import { WorkItem } from "@/types/work_item";
import { Row } from "@tanstack/react-table";

const statusColors = {
  backlog: "bg-gray-500 text-white",
  todo: "bg-gray-200 text-black",
  in_progress: "bg-primary-400 text-white",
  completed: "bg-green-400 text-white",
};

const StatusCell = ({ getValue, row }: { getValue: () => string; row: Row<WorkItem> }) => {
  const colorClasses = statusColors[row.original.status_category] || "bg-gray-500 text-white";
  return <div className={`w-[120px] rounded-xl text-center ${colorClasses}`}>{getValue()}</div>;
};

export default StatusCell;
