import { Link } from "@tanstack/react-router";
import { Member } from "@/types/member";

function AssigneeCell({ getValue }: { getValue: () => Member }) {
  const row = getValue();
  if (!row) return null;
  return (
    <Link to="/members/$id" params={{ id: row.id.toString() }} className="link">
      <div className="h-full w-full">{row.name}</div>
    </Link>
  );
}

export default AssigneeCell;
